<template>
    <a
        class="flex flex-col items-center justify-center h-10 min-w-40 sm:px-2 align-middle border border-transparent text-sm font-medium rounded-md shadow-sm sm:text-base"
        :class="[...themeClasses]"
        :id="id"
        :title="title"
        :href="isLinkVisible ? link : '#'"
        @click="() => handleClick(id)"
    >
        <span>{{ label }}</span>
    </a>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRef, computed } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'InteractionTarget',
        props: {
            id: {
                /** @type {import('vue').PropType<String>} */
                type: String,
                required: true,
            },
            theme: {
                /** @type {import('vue').PropType<String>} */
                type: String,
                default: 'primary',
            },
            type: {
                /** @type {import('vue').PropType<String>} */
                type: String,
                default: 'link',
            },
            link: {
                /** @type {import('vue').PropType<String>} */
                type: String,
                default: '#',
                // 'mailto:ipi@rit.edu?subject=Purchase or Renew Subscription',
            },
            label: {
                /** @type {import('vue').PropType<String>} */
                type: String,
                default: 'Button',
            },
            title: {
                /** @type {import('vue').PropType<String>} */
                type: String,
                default: 'Click Me!',
            },
        },
        emits: ['interact'],
        setup(props, context) {
            // Get the prop reactive.
            const type = toRef(props, 'type');
            const link = toRef(props, 'link');
            const theme = toRef(props, 'theme');

            // Computed properties.
            const isLink = computed(() => type.value === 'link');
            const isButton = computed(() => type.value === 'button');
            const hasDefinedLink = computed(() => link.value?.length > 0);
            const isLinkVisible = computed(
                () => isLink.value && hasDefinedLink.value
            );

            // Create the dictionary of theme classes.
            const themes = {
                primary: [
                    'text-white',
                    'bg-primary-800',
                    'hover:bg-primary-900',
                    'focus:outline-none',
                    'focus:ring-2',
                    'focus:ring-offset-2',
                    'focus:ring-primary-600',
                ],
                secondary: [
                    'text-white',
                    'bg-secondary-800',
                    'hover:bg-secondary-900',
                    'focus:outline-none',
                    'focus:ring-2',
                    'focus:ring-offset-2',
                    'focus:ring-secondary-600',
                ],
            };

            // Additional classses based on the current theme.
            const themeClasses = computed(() => {
                const keys = Object.keys(themes);
                const selected = keys.includes(theme.value)
                    ? theme.value
                    : keys[0];
                return themes[selected];
            });

            // Fire the click event.
            const handleClick = () => {
                if (isButton.value) {
                    context.emit('interact', props.id);
                }
            };

            return {
                themeClasses,
                isLinkVisible,
                handleClick,
            };
        },
    });
</script>
