<template>
    <PageWrapper>
        <div v-if="isSuperUser">
            <SwitchOrganizationGrid
                label="Select Organization"
                @select="$emit('select', $event)"
                @error="$log('Failed to select an organization...', $event)"
            />
        </div>
    </PageWrapper>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onBeforeMount, computed, ref } from 'vue';
    import profile from '@/api/v2/profile';

    // <!-- COMPOSABLES -->
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useExpiredSubscriptionGate } from '@/hooks/gates';

    // <!-- COMPONENTS -->
    import PageWrapper from '@/components/PageWrapper.vue';

    // <!-- FIXTURES -->
    import SwitchOrganizationGrid from '@/features/switch-organization/components/SwitchOrganizationGrid.vue';

    // <!-- TYPES -->
    /** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
    import { ECNBState } from '@/store/types/ECNBStore';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SelectOrganization',
        components: {
            PageWrapper,
            SwitchOrganizationGrid,
        },
        emits: ['select', 'error'],
        setup(props, context) {
            // ==== COMPOSABLES ====
            const store = useStore();
            const router = useRouter();
            const {
                fetchCurrentUser,
                selectOrganization,
                isCurrentSubscriptionExpired,
                isSuperUser,
            } = useExpiredSubscriptionGate();

            /** @type {Vue.ComputedRef<Store<ECNBState>['state']['users']['me']>} */
            const user = computed(() => store.state.users.me);

            // Prepare state tracker for default organization.
            /** @type {Vue.Ref<Organization.Model[]>} */
            const userOrganizations = ref([]);

            // Default organization from the user organizations.
            const defaultOrganization = computed(() => {
                const list = userOrganizations.value ?? [];
                return list?.[0];
            });

            // Fetch the user organizations.
            const fetchUserOrganizations = async () => {
                // Get the first organization in the list, by default.
                const response = await profile.fetchUserOrganizations();
                userOrganizations.value = response.mapOr([], (arr) => arr);
                return userOrganizations.value;
            };

            // Redirect back to the login page, if no user.
            const redirectToLogin = async () => {
                console.log(`No user is logged in.`);
                await store.dispatch('logout');
                await router.push('/login');
            };

            // Redirect to the select account page, with appropriate state.
            const redirectToSelectAccount = async () => {
                // Get the latest user organizations.
                await fetchUserOrganizations();

                // Select the default organization.
                await selectOrganization(defaultOrganization.value);

                // Save the default organization.
                if (!isSuperUser.value && isCurrentSubscriptionExpired.value) {
                    // Redirect to the expired subscription page.
                    return await router.push('/expired-subscription');
                }

                // Redirect.
                return await router.push('/select-account');
            };

            // Handle initial lifecycle.
            onBeforeMount(async () => {
                // Update the current user.
                await fetchCurrentUser();

                // We should redirect to the '/select-account' route if not a super user.
                if (user.value != null && !isSuperUser.value) {
                    console.log(`The current user is not a super user...`);
                    redirectToSelectAccount();
                } else if (user.value == null) {
                    console.log(`There is no user...`);
                    redirectToLogin();
                }
            });

            // Expose properties.
            return {
                isSuperUser,
            };
        },
    });
</script>
