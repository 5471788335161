// <!-- API -->
import { getFormElements } from '@/utils/html';

/**
 * Get reference to sibling input.
 * @param {Readonly<HTMLFormElement>} form Form element.
 * @param {Readonly<String>} name Form element to focus.
 * @returns {HTMLInputElement}
 */
export const getFormElementByName = (form, name) => {
    const elements = getFormElements(form);
    if (elements?.length > 0) {
        return elements?.[name];
    }
    return null;
};

// <!-- DEFAULT -->
export default getFormElementByName;
