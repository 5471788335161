/**
 * Forward an event target
 * @template {EventTarget} [T=EventTarget]
 * @param {{ target: T }} e Event with a supplied target.
 */
export const withEventTarget = (e) => {
    /**
     * @type {Promise<T>}
     * Create a promise that resolves when the target is present.
     */
    const context = new Promise((resolve, reject) => {
        if (e?.target !== undefined) {
            resolve(e?.target);
        } else {
            reject(`EventTarget is not defined.`);
        }
    });

    /**
     * Get the target, if defined.
     * @returns {T}
     */
    const get = () => (e?.target === undefined ? e?.target : null);

    /**
     * Get the target, if defined. Else get a default value.
     * @template [V=any]
     * @param {V} [defaultValue] Default value.
     * @returns {T | V}
     */
    const getOrElse = (defaultValue) =>
        e?.target === undefined ? e?.target : defaultValue;

    /**
     * Get the target, if defined. Else throw a {@link TypeError}.
     * @throws {TypeError} If `target` is not defined.
     * @returns {T}
     */
    const getOrThrow = () => {
        if (e?.target === undefined) {
            throw new TypeError(`EventTarget is not defined.`);
        }
        return e?.target;
    };

    // <!-- EXPOSE -->
    return Object.assign(context, {
        /** @type {get} */
        get: get.bind(context),
        /** @type {getOrElse} */
        getOrElse: getOrElse.bind(context),
        /** @type {getOrThrow} */
        getOrThrow: getOrThrow.bind(context),
    });
};

// <!-- DEFAULT -->
export default withEventTarget;
