// <!-- API -->
import { computed, ref, reactive } from 'vue';
import profile from '@/api/v2/profile';
import organizations from '@/api/v2/organizations';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';

// <!-- COMPONENTS -->
import SwitchOrganizationTableIcons from '@/features/switch-organization/components/SwitchOrganizationTableIcons.vue';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useAlerts } from '@/components/alerts/hooks/useAlerts';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';
import { useExpiredSubscriptionGate } from '@/hooks/gates';
import { useCaseInsensitiveLocaleCompare } from '@/utils/sorters';
/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
/** @typedef {import('@/models/v1/profile/CurrentUserProfile').CurrentUserProfileResource} CurrentUserProfileResource */

// <!-- DEFINITION -->
/**
 * Provides access to all composable submodules.
 * @template {Vue.EmitsOptions} [E=Vue.EmitsOptions]
 */
class SwitchOrganizations {
    /**
     * Instantiate a new SwitchOrganizations composable.
     * @param {Object} [props] Props to pass to the SwitchOrganizations.
     * @param {Store<ECNBState>} [props.store] Optional store to provide. Will be instantiated if nothing is provided.
     * @param {Router.Instance} [props.router] Optional router to provide. Will be instantiated if nothing is provided.
     * @param {ReturnType<useAlerts>} [props.alerts] Alerts composable.
     * @param {Vue.SetupContext<E>} [context] Setup context used to emit events.
     */
    constructor(props, context) {
        // Deconstruct parameters.
        const { store, router, alerts } = props ?? {};

        /** @type {Store<ECNBState>} */
        this.store = store ?? useStore();

        /** @type {Router.Instance} */
        this.router = router ?? useRouter();

        // Expired subscription gate.
        this.expired = useExpiredSubscriptionGate();

        /** @type {ReturnType<useAlerts>} */
        this.alerts = alerts ?? useAlerts();

        /** @type {SwitchOrganizationConstants<E>} */
        this.constants = new SwitchOrganizationConstants(this);

        /** @type {SwitchOrganizationState<E>} */
        this.state = new SwitchOrganizationState(this);

        /** @type {SwitchOrganizationCache<E>} */
        this.cached = new SwitchOrganizationCache(this);

        /** @type {SwitchOrganizationsAPI<E>} */
        this.api = new SwitchOrganizationsAPI(this);

        /** @type {context['emit']} */
        this.emit = context.emit;

        /** @type {Boolean} */
        this.initialized = false;
    }

    /**
     * Initialize respective submodule
     */
    initialize() {
        const $context = this;
        if (!$context.initialized) {
            // Initialize sequentially. These must be synchronous.
            $context.constants.initialize();
            $context.state.initialize();
            $context.cached.initialize();
            $context.api.initialize();
            $context.initialized = true;
            // If an onInit event exists, invoke it now.
            $context.api.events?.onInit?.();
            // Return the context.
            return $context;
        }
    }

    /**
     * Get access to the module setters.
     */
    get register() {
        const $context = this;
        return {
            /** @param {SwitchOrganizations<E>['constants']} instance */
            constants: (instance) => {
                $context.constants = instance;
                return $context;
            },
            /** @param {SwitchOrganizations<E>['state']} instance */
            state: (instance) => {
                $context.state = instance;
                return $context;
            },
            /** @param {SwitchOrganizations<E>['cached']} instance */
            cached: (instance) => {
                $context.cached = instance;
                return $context;
            },
            /** @param {SwitchOrganizations<E>['api']} instance */
            api: (instance) => {
                $context.api = instance;
                return $context;
            },
        };
    }

    /**
     * Get reactive data and computed properties.
     * @returns {Omit<SwitchOrganizationConstants<E>, 'initialize'> & Omit<SwitchOrganizationState<E>, 'initialize'> & Omit<SwitchOrganizationCache<E>, 'initialize' | 'initStatusConditionals' | 'initOrganizationTargets'>}
     */
    get data() {
        const $context = this;
        return {
            ...$context.constants,
            ...$context.state,
            ...$context.cached,
        };
    }

    /**
     * Get the actions.
     */
    get actions() {
        const $context = this;
        return {
            ...$context.api.getters,
            ...$context.api.events,
            ...$context.api.methods,
        };
    }
}

// ==== CONSTANTS ====
/**
 * @class
 * Submodule for the {@link SwitchOrganizations} composable.
 * @template {Vue.EmitsOptions} [E=Vue.EmitsOptions]
 */
class SwitchOrganizationConstants {
    /**
     * Instantiate submodule.
     * @param {SwitchOrganizations<E>} context
     */
    constructor(context) {
        /** @type {SwitchOrganizations<E>} */
        this.context = context;
        this.context.register.constants(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        /** Loading status IDs. */
        this.LoadingIDs = /** @type {const} */ ([
            'idle',
            'loading',
            'success',
            'failure',
        ]);

        /** @type {Profile.Model} */
        this.CurrentUser = this.context.store.state.users.me;

        /**
         * Default column definition.
         */
        this.defaultColDef = Object.freeze({
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            suppressMovable: true,
            suppressMenu: true,
            lockPosition: true,
            headerName: '',
            cellClass: 'flex items-center text-left leading-5 break-normal',
            flex: 1,
        });
    }
}

// ==== STATE ====
/**
 * @class
 * Submodule for the {@link SwitchOrganizations} composable.
 * @template {Vue.EmitsOptions} [E=Vue.EmitsOptions]
 */
class SwitchOrganizationState {
    /**
     * Instantiate submodule.
     * @param {SwitchOrganizations<E>} context
     */
    constructor(context) {
        /** @type {SwitchOrganizations<E>} */
        this.context = context;
        this.context.register.state(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== STATUS ====
        /** @type {Vue.Ref<'idle' | 'loading' | 'success' | 'failure'>} */
        this.status = ref('idle');

        // ==== AG GRID ====
        /** @type {{ grid: AgGrid.GridApi, column: AgGrid.ColumnApi }} */
        this.api = reactive({
            grid: null,
            column: null,
        });

        // /** @type {Vue.Ref<Array<OrganizationResource>>} */
        //this.rowData = ref([]);
        this.rowData = ref([
            {
                id: '1',
                name: 'Test Org 1',
                lastUploadDate: '12/17/2023',
            },
        ]);

        /** @type {Vue.Ref<Array<AgGrid.ColumnDef>>} */
        this.colDefs = ref([]);
    }
}

// ==== COMPUTED PROPERTIES ====
/**
 * @class
 * Submodule for the {@link SwitchOrganizations} composable.
 * @template {Vue.EmitsOptions} [E=Vue.EmitsOptions]
 */
class SwitchOrganizationCache {
    /**
     * Instantiate submodule containing computed properties.
     * @param {SwitchOrganizations<E>} context
     */
    constructor(context) {
        /** @type {SwitchOrganizations<E>} */
        this.context = context;
        this.context.register.cached(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== CONDITIONALS (STATUS) ====
        this.initStatusConditionals();
    }

    /**
     * Initialize the status conditionals.
     */
    initStatusConditionals() {
        const { state } = this.context;

        /** @type {Vue.ComputedRef<Boolean>} */
        this.isIdle = computed(() => {
            return state.status.value === 'idle';
        });

        /** @type {Vue.ComputedRef<Boolean>} */
        this.isLoading = computed(() => {
            return (
                (state.status.value === 'loading') ===
                //|| this.context.organizations.isFetching.value
                true
            );
        });

        /** @type {Vue.ComputedRef<Boolean>} */
        this.isLoadedWithSuccess = computed(() => {
            return state.status.value === 'success';
        });

        /** @type {Vue.ComputedRef<Boolean>} */
        this.isLoadedWithFailure = computed(() => {
            return state.status.value === 'failure';
        });
    }
}

// ==== API ====
/**
 * @class
 * Submodule for the {@link SwitchOrganizations} composable.
 * @template {Vue.EmitsOptions} [E=Vue.EmitsOptions]
 */
class SwitchOrganizationsAPI {
    /**
     * Instantiate submodule containing computed properties.
     * @param {SwitchOrganizations<E>} context
     */
    constructor(context) {
        /** @type {SwitchOrganizations<E>} */
        this.context = context;
        this.context.register.api(this);
    }

    /**
     * Initialize submodule.
     */
    initialize() {
        // ==== GETTERS ====
        this.initGetters();
        // ==== SETTERS ====
        this.initSetters();
        // ==== METHODS ====
        this.initMethods();
        // ==== EVENTS ====
        this.initEventHandlers();
    }

    initGetters() {
        const $api = this;
        const $emit = this.context.emit;
        const { state, store } = $api.context;

        /**
         * Get the default column definitions.
         * @returns {Readonly<AgGrid.ColumnDef>}
         */
        const getDefaultColDef = () => this.context.constants.defaultColDef;

        /**
         * Get keyed column definitions.
         */
        const getColumnSchema = () => {
            return Object.freeze({
                /** @type {AgGrid.ColumnDef} */
                spacer: {
                    suppressMovable: true,
                    suppressNavigable: true,
                    suppressKeyboardEvent: () => true,
                    suppressHeaderKeyboardEvent: () => true,
                    lockPosition: true,
                    filter: false,
                    minWidth: 0,
                    flex: 0.5,
                },
                /** @type {AgGrid.ColumnDef} */
                icons: {
                    headerName: '',
                    field: 'icons',
                    cellRendererFramework: SwitchOrganizationTableIcons,
                    lockPosition: true,
                    filter: false,
                    maxWidth: 60,
                    cellRendererParams: {
                        /**
                         * Handle switching to an organization.
                         * @param {Object} event
                         * @param {Number} index Organization index.
                         */
                        handleSwitchOrganization: async (event, index) => {
                            try {
                                // Get the account id to switch to.
                                const id = Number(
                                    state.rowData.value[index]?.id
                                );

                                // Set the store's current organization to the requested one.
                                const next =
                                    await this.context.expired.selectOrganization(
                                        { id }
                                    );

                                // Push the latest selections.
                                $emit('select', next);

                                // Redirect to the select account page.
                                console.log(`Selecting organization...`);

                                // Clear session storage of selected account, if present.
                                sessionStorage.removeItem('selected_account');

                                // Redirect to the Select Account page.
                                this.context.router.push('/select-account');
                            } catch (e) {
                                // Notify listeners that the organization was not selected.
                                $emit('error', e);
                            }
                        },
                    },
                },
                /** @type {AgGrid.ColumnDef} */
                name: {
                    headerName: 'Organization Name',
                    field: 'name',
                    minWidth: 350,
                    maxWidth: 800,
                    wrapText: true,
                    flex: 2,
                    sort: 'asc',
                    comparator: useCaseInsensitiveLocaleCompare(),
                },
                /** @type {AgGrid.ColumnDef} */
                id: {
                    headerName: 'Organization ID',
                    field: 'id',
                    maxWidth: 130,
                    sort: 'asc',
                },
                /** @type {AgGrid.ColumnDef} */
                expirationDate: {
                    headerName: 'Expiration Date',
                    field: 'expirationDate',
                    valueFormatter: $api.methods.formatExpirationDate,
                    minWidth: 150,
                    maxWidth: 400,
                    filter: false,
                    cellClass: `${$api.context.constants.defaultColDef.cellClass} text-center sm:text-left`,
                },
                /** @type {AgGrid.ColumnDef} */
                lastUpdatedDate: {
                    headerName: 'Last Updated',
                    field: 'lastUpdatedDate',
                    valueFormatter: $api.methods.formatDate,
                    minWidth: 150,
                    maxWidth: 400,
                    filter: false,
                    sort: 'desc',
                    cellClass: `${$api.context.constants.defaultColDef.cellClass} text-center sm:text-left`,
                },
                /** @type {AgGrid.ColumnDef} */
                lastUploadDate: {
                    headerName: 'Last Upload',
                    field: 'lastUploadDate',
                    valueFormatter: $api.methods.formatDate,
                    minWidth: 150,
                    maxWidth: 400,
                    filter: false,
                    sort: 'desc',
                    cellClass: `${$api.context.constants.defaultColDef.cellClass} text-center sm:text-left`,
                },
            });
        };

        /**
         * Get column definitions in ordered array.
         * @returns {AgGrid.ColumnDef[]}
         */
        const getColumnDefs = () => {
            const schema = getColumnSchema();
            return [
                schema.icons,
                schema.name,
                schema.expirationDate,
                // schema.lastUpdatedDate,
                // schema.lastUploadDate,
                schema.spacer,
            ];
        };

        /** Getter calls that provide live accessors. */
        this.getters = {
            getDefaultColDef,
            getColumnSchema,
            getColumnDefs,
        };
    }

    initSetters() {
        const $api = this;
        const { state } = $api.context;

        /**
         * Save the grid api reference.
         * @param {AgGrid.GridApi} api
         */
        const setGridApi = (api) => (state.api.grid = api);

        /**
         * Save the column api reference.
         * @param {AgGrid.ColumnApi} api
         */
        const setColumnApi = (api) => (state.api.column = api);

        /**
         * Set the loading status.
         * @param {'idle' | 'loading' | 'success' | 'failure'} [id]
         */
        const setLoading = (id = 'idle') => {
            state.status.value = id ?? 'idle';
        };

        /**
         * Set the row data.
         */
        const setRowData = (data) => {
            state.rowData.value = [...data];
        };

        /** Setters for mutation of the state. */
        this.setters = {
            setGridApi,
            setColumnApi,
            setLoading,
            setRowData,
        };
    }

    initEventHandlers() {
        const $api = this;
        const { state, alerts } = $api.context;
        /**
         * When organizations index is loaded/refreshed, update the row data.
         * @param {Organization.Model[]} organizations
         */
        const onUpdateOrganizations = (organizations) => {
            const { setRowData } = $api.setters;
            const organizationData = organizations.map((org) => {
                const id = org.id;
                const name = org.name;
                const subscription = org.currentSubscription;
                const missing = subscription == null;
                const expirationDate = missing
                    ? 'NONE'
                    : subscription?.expireAt?.toLocaleDateString('en-ca') ??
                      null;

                // Return the row data.
                return {
                    id,
                    name,
                    expirationDate,
                };
            });
            setRowData(organizationData);
        };

        /**
         * After initialization, run this event.
         */
        const onInit = async () => {
            // Initialize the column definitions.
            const { getColumnDefs } = $api.getters;
            state.colDefs.value = [...getColumnDefs()];
        };

        /** @param {Events.GridReadyEvent} e */
        const onGridReady = (e) => {
            const { setGridApi, setColumnApi } = $api.setters;
            setGridApi(e?.api);
            setColumnApi(e?.columnApi);
        };

        /** @param {Events.ColumnResizedEvent} e */
        const onColumnResized = (e) => {
            // Refresh cells on column resize.
            state.api.grid.refreshCells();
        };

        /** Event handlers and callbacks. */
        this.events = {
            onInit,
            onGridReady,
            onColumnResized,
            onUpdateOrganizations,
        };
    }

    initMethods() {
        const $api = this;
        const { state } = $api.context;

        /**
         * Format the date.
         * @type {AgGrid.ValueFormatterFunc}
         */
        const formatDate = (params) => {
            /** datetime value in the format of 'yyyy-MM-DDThh:mm:ss.sssZ' */
            const datetime = /** @type {String} */ (params.value);
            if (!isNil(datetime) && datetime !== '') {
                const [date, time] = datetime.split('T');
                return date;
            }
            return 'No date provided.';
        };

        /**
         * Format the expiration date.
         * @type {AgGrid.ValueFormatterFunc}
         */
        const formatExpirationDate = (params) => {
            if (params.value === 'NONE') {
                return 'No subscription.';
            }

            /** datetime value in the format of 'yyyy-MM-DDThh:mm:ss.sssZ' */
            const datetime = /** @type {String} */ (params.value);
            if (!isNil(datetime) && datetime !== '') {
                const [date, time] = datetime.split('T');
                return date;
            }
            return 'No expiration date.';
        };

        /**
         * Create watcher that syncs the loading overlay.
         * @type {Vue.WatchEffect}
         */
        const syncLoadingOverlay = (onCleanup) => {
            const { data } = $api.context;
            // Show or hide the overlay.
            const grid = data?.api?.grid;
            const isOverlayVisible = data.isLoading.value === true;
            const action = isOverlayVisible
                ? 'showLoadingOverlay'
                : 'hideOverlay';
            grid?.[action]();
            // Register onCleanup call.
            onCleanup(() => {
                // If data in the grid empty, show the no rows overlay.
                if (data?.rowData?.value?.length > 0) {
                    // Hide all overlays if something goes wrong.
                    data?.api?.grid?.hideOverlay();
                } else {
                    // Hide all overlays if something goes wrong.
                    data?.api?.grid?.showNoRowsOverlay();
                }
            });
        };

        /**
         * Update the row data after requesting accounts from the cached index.
         */
        const refreshOrganizations = async () => {
            const { store, router, expired } = $api.context;
            const { setLoading } = $api.setters;
            try {
                console.time(
                    `[organizations::index] - Refreshing Organizations index:`
                );
                setLoading('loading');

                // Get the latest organizations.
                let response = null;
                let list = [];
                if (expired.isSuperUser.value) {
                    response = await organizations.fetchOrganizations();
                    list = response.mapOr([], (arr) => arr);
                } else {
                    response = await profile.fetchUserOrganizations();
                    const index = response.mapOr([], (arr) => arr);
                    // Map organizations into full items.
                    list = await Promise.all(
                        index.map(async (org) => {
                            const response =
                                await organizations.fetchOrganizationById(org);
                            const organization = response.mapOr(null, (o) => o);
                            return organization;
                        })
                    );
                }

                // Update the list of organizations.
                $api.events.onUpdateOrganizations(list);
                setLoading('success');

                // Error handling.
                if (response.isErr) {
                    // Throw error, if one was received.
                    throw response.error;
                }
            } catch (e) {
                setLoading('failure');
                if ('code' in e && e.code === 'ERR_BAD_REQUEST') {
                    console.log(
                        `The user is not authenticated or no organizations could be found.`,
                        e
                    );
                    localStorage.clear();
                    sessionStorage.clear();
                    await this.context.store.dispatch(`logout`);
                } else {
                    console.error(e);
                    // Clear login information and then redirect.
                    localStorage.clear();
                    sessionStorage.clear();
                    await this.context.store.dispatch(`logout`);
                    await router.push('login');
                }
            } finally {
                console.timeEnd(
                    `[organizations::index] - Refreshing Organizations index:`
                );
            }
        };

        /** Event triggers and methods. */
        this.methods = {
            formatDate,
            formatExpirationDate,
            refreshOrganizations,
            syncLoadingOverlay,
        };
    }
}

/**
 * Composable function that returns the initialized context object.
 * @template {Vue.EmitsOptions} [E=Vue.EmitsOptions]
 * @param {Object} props
 * @param {Vue.SetupContext<E>} context
 *
 */
export const useSwitchOrganization = (props, context) => {
    const hook = new SwitchOrganizations(props, context);
    return hook.initialize();
};

// <!-- DEFAULT -->
export default useSwitchOrganization;
