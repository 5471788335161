/**
 * Get the cookie by name.
 *
 * @param {String} name
 * @returns {[key: String, value: String]}
 */
export const getCookie = (name) => {
    if (!name) {
        throw new TypeError('Cannot get cookie with no name.');
    }

    const key = `${name}=`;
    const cookies = document.cookie.split(';');
    let value = '';
    for (let index = 0; index < cookies.length; index++) {
        let cookie = cookies[index];
        while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(key) == 0) {
            value = cookie.substring(key.length, cookie.length);
            return [name, value];
        }
    }
    // Result.
    return null;
};

/**
 * Set a cookie key/value pair, with optional expiry date.
 *
 * @param {String} name Cookie name.
 * @param {String} value Value.
 * @param {Number} expiry Offset expiration (after current time).
 * @returns {String} Serialized cookie.
 */
export const setCookie = (name, value, expiry = null) => {
    if (!name) {
        throw new TypeError('Cannot set cookie with no name.');
    }

    // Prepare the cookie arguments.
    const cookie = [];

    if (!value) {
        console.warn(`No cookie value provided. Defaulting to empty string.`);
    }

    // Add value.
    cookie.push([name, value ?? '']);

    if (!expiry) {
        console.warn(
            `No cookie expiration date provided. Cookie will be deleted when the browser closes.`
        );
    } else {
        const expiration = new Date();
        expiration.setTime(expiration.getTime() + expiry);
        cookie.push(['expires', expiration.toUTCString()]);
    }

    // Add path.
    cookie.push(['path', '/']);

    // Get the persisted cookie string.
    const serialized = cookie
        .reduce((entries, next) => {
            const key = next[0];
            const value = next[1];
            const updated = [...entries, `${key}=${value}`];
            return updated;
        }, [])
        .join('; ');

    // Save the cookie.
    document.cookie = serialized;

    // Return the serailized string.
    return serialized;
};

/**
 * Clear cookie.
 *
 * @param {String} name
 */
export const clearCookie = (name) => {
    if (getCookie(name)) {
        document.cookie = `${name}=; Max-Age=-99999999;`;
    }
};

export const useCookie = (name) => {
    if (!name) {
        throw new TypeError('Cannot get or set cookie with no name.');
    }

    /**
     * Get the named cookie.
     *
     * @returns {[key: String, value: String]}
     */
    const getNamedCookie = () => getCookie(name);

    /**
     * Set the named cookie.
     *
     * @param {String} value Value.
     * @param {Number} expiry Offset expiration (after current time).
     * @returns {String} Serialized cookie.
     */
    const setNamedCookie = (value, expiry = null) =>
        setCookie(name, value, expiry);

    /**
     * Clear existing cookie.
     */
    const clearNamedCookie = () => {
        clearCookie(name);
    };

    // Return ther read/write/delete API.
    return {
        read: getNamedCookie,
        write: setNamedCookie,
        delete: clearNamedCookie,
    };
};

export const showCookies = () => {
    const cookies = document.cookie;
    console.dir({ cookies: cookies.split(';') });
    window.alert(`Showing cookies in the console:`);
};

export default {
    useCookie,
    getCookie,
    setCookie,
    clearCookie,
    showCookies,
};
