<template>
    <div
        class="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8"
    >
        <div class="max-w-max mx-auto">
            <main class="sm:flex">
                <p class="text-4xl font-extrabold text-primary-800 sm:text-5xl">
                    {{ status }}
                </p>
                <div class="sm:ml-6">
                    <div class="sm:border-l sm:border-gray-200 sm:pl-6">
                        <h1
                            class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl"
                        >
                            {{ title }}
                        </h1>
                        <p class="mt-1 text-xs sm:text-base text-gray-500">
                            {{ description }}
                        </p>
                        <ul class="mt-1 text-xs sm:text-base text-gray-500">
                            <li
                                v-for="(message, index) in messages"
                                :key="index"
                            >
                                {{ message }}
                            </li>
                        </ul>
                    </div>
                    <ul
                        class="mt-4 flex flex-col gap-2 sm:flex-row sm:gap-4 sm:mt-10 sm:pl-6"
                    >
                        <li
                            v-for="(interaction, index) in visibleInteractions"
                            :key="`${index}-${interaction.id}`"
                        >
                            <InteractionTarget
                                :id="interaction.id"
                                :theme="interaction.theme"
                                :type="interaction.type"
                                :link="interaction.link?.value"
                                :label="interaction.label"
                                :title="interaction.description"
                                @interact="triggerInteraction"
                            />
                        </li>
                        <!-- <a
                            href="#"
                            @click="$emit('login')"
                            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-800 hover:bg-primary-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
                        >
                            Return to Login
                        </a>
                        <a
                            href="mailto:ipi@rit.edu?subject=Purchase or Renew Subscription"
                            title="Email IPI Administrators"
                            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-primary-900 bg-grey-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grey-100"
                        >
                            Contact Support
                        </a> -->
                    </ul>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRef, computed } from 'vue';

    // <!-- COMPONENTS -->
    import InteractionTarget from '../InteractionTarget.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ExpiredWrapper',
        components: {
            InteractionTarget,
        },
        props: {
            status: {
                /** @type {import('vue').PropType<Number>} */
                type: Number,
                default: 402,
            },
            title: {
                /** @type {import('vue').PropType<String>} */
                type: String,
                default: 'Payment Required',
            },
            description: {
                /** @type {import('vue').PropType<String>} */
                type: String,
                default:
                    'You have tried to access an organization with an expired subscription.',
            },
            messages: {
                /** @type {import('vue').PropType<String[]>} */
                type: Array,
                default: () => [],
            },
            interactions: {
                /** @type {import('vue').PropType<Record<string,import('./../../hooks/useExpiredSubscriptionPage').Interaction>>} */
                type: Object,
                default: () => {},
            },
        },
        emits: ['interact'],
        setup(props) {
            const interactions = toRef(props, 'interactions');

            const visibleInteractions = computed(() => {
                const items = Object.values(interactions.value);
                const visible = items.filter((i) => !!i.visible.value);
                return visible;
            });

            /**
             * Trigger the interaction.
             * @param {string} id
             */
            const triggerInteraction = (id) => {
                const items = Object.values(interactions.value);
                const item = items.find((interaction) => interaction.id === id);
                item?.click();
            };

            return {
                visibleInteractions,
                triggerInteraction,
            };
        },
    });
</script>
