// <!-- API -->
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { createEventHook } from '@vueuse/shared';
import { useExpiredSubscriptionGate } from '@/hooks/gates';

// <!-- TYPES -->

/** @typedef {import('vuex').Store<import('@/store/types/ECNBStore').ECNBState>} Store */
/** @typedef {ReturnType<useExpiredSubscriptionGate>} SubscriptionGate */
/** @typedef {Partial<{ store: Store, router: Router.Instance, gate: SubscriptionGate }>} UseExpiredSubscriptionPageOptions */
/** @typedef {ReturnType<Page.expose>} UseExpiredSubscriptionPageReturn */

// <!-- HELPERS -->

/**
 * Representation of the props.
 */
class Props {
    /**
     * Define the formal props object.
     * @param {UseExpiredSubscriptionPageOptions} options
     */
    constructor(options) {
        // Declare the options.
        this.store = options?.store ?? useStore();
        this.router = options?.router ?? useRouter();
        this.gate = options?.gate ?? useExpiredSubscriptionGate();
    }
}

/**
 * Internal state for the page.
 */
class State {
    /**
     * Define the expired subscription page state.
     * @param {Props} props
     */
    constructor({ gate }) {
        // DESTRUCTURE PROPS
        const {
            user,
            organization,
            expirationDate,
            isCurrentSubscriptionMissing,
            isOrganizationMember,
            isOrganizationAdministrator,
        } = gate;

        // CONSTANTS
        {
            this.status = 402;
            this.title = Message.forTitle();
            this.description = Message.forDescription();
        }

        // DERIVED STATE
        {
            this.name = computed(() => organization.value?.name);
            this.emails = computed(() => organization.value?.email ?? {});
            this.messages = computed(() => {
                const messages = [];
                const _ = organization.value;

                // Get the prompt.
                const isAdministrator =
                    isOrganizationAdministrator.value == true;
                messages.push(Message.forUser(isAdministrator));

                // Conditionally get the expiration date string.
                // if (isCurrentSubscriptionMissing.value) {
                const expiredAt = expirationDate.value;
                messages.push(Message.forExpirationDate(expiredAt));
                // }

                // Return the messages.
                return messages;
            });
        }

        // CONDITIONS
        {
            this.isContactOrganizationEnabled = computed(
                () => isOrganizationMember.value === true
            );

            this.isContactBillingEnabled = computed(
                () => isOrganizationMember.value === true
            );

            this.isContactSupportEnabled = computed(
                () => isOrganizationAdministrator.value === true
            );

            this.isRenewSubscriptionEnabled = computed(
                () => isOrganizationAdministrator.value === true
            );
        }

        // Interactions
        {
            // Create the logout interaction. This is hidden and a holdover.
            const logout = new Interaction({
                id: 'login',
                type: 'button',
                theme: 'primary',
                label: 'Return to Login',
                description: 'Return to the login form.',
                visible: ref(false),
            });

            // Create the renewal interaction. This is hidden and a holdover.
            const renewal = new Interaction({
                id: 'renew',
                type: 'button',
                theme: 'primary',
                label: 'Renew Subscription',
                description: 'Go to the renew subscription form.',
                visible: computed(() => isOrganizationAdministrator.value),
            });

            // Create the contact administrator button, for organization members.
            const contactAdministrator = new Interaction({
                id: 'contact-admin',
                type: 'link',
                link: computed(() => {
                    const to = [organization.value?.email?.contact];
                    const subject = 'Re: Expired eClimateNotebook Subscription';
                    const body = [
                        'To Whom It May Concern,',
                        'I am reaching out to discuss the expired eClimateNotebook subscription.',
                        '',
                        'Regards,',
                        `${user.value?.firstName} ${user.value?.lastName}`,
                    ];
                    return Email.make({ to, subject, body });
                }),
                theme: 'primary',
                label: 'Contact Administrator',
                description: 'Contact your administrator.',
                visible: computed(
                    () =>
                        isOrganizationMember.value &&
                        !isOrganizationAdministrator.value
                ),
            });

            // Create the contact billing button, for organization administrators.
            const contactBilling = new Interaction({
                id: 'contact-billing',
                type: 'link',
                link: computed(() => {
                    const to = [organization.value?.email?.billing];
                    const cc = [organization.value?.email?.contact];
                    const subject = 'Re: Expired eClimateNotebook Subscription';
                    const body = [
                        'To Whom It May Concern,',
                        'I am reaching out to discuss renewal of the expired eClimateNotebook subscription.',
                        '',
                        'Regards,',
                        `${user.value?.firstName} ${user.value?.lastName}`,
                    ];
                    return Email.make({ to, cc, subject, body });
                }),
                theme: 'secondary',
                label: 'Contact Billing',
                description: 'Contact your billing representative.',
                visible: computed(() => isOrganizationAdministrator.value),
            });

            // Create the contact support button, for organization administrators.
            const contactSupport = new Interaction({
                id: 'contact-support',
                type: 'link',
                link: computed(() => {
                    const to = ['ipi@rit.edu'];
                    const subject = 'Re: Expired eClimateNotebook Subscription';
                    const body = [
                        'To Whom It May Concern,',
                        '',
                        "I am reaching out with questions about my organization's expired subscription.",
                        '',
                        'Regards,',
                        `${user.value?.firstName} ${user.value?.lastName}`,
                    ];
                    return Email.make({ to, subject, body });
                }),
                theme: 'secondary',
                label: 'Contact Support',
                description: 'Contact the IPI support team.',
                visible: computed(() => isOrganizationAdministrator.value),
            });

            // Get the available interactions.
            this.interactions = {
                logout,
                renewal,
                contactAdministrator,
                contactBilling,
                contactSupport,
            };
        }
    }
}

/**
 * Representation for the action handler.
 */
class Actions {
    /**
     * Define the expired subscription page actions.
     * @param {Props} props
     * @param {State} state
     */
    constructor({ store, router, gate }, state) {
        // Destructure props.
        const {
            user,
            organization,
            subscriptions,
            fetchCurrentUser,
            fetchSelectedOrganization,
            isCurrentSubscriptionActive,
            isOrganizationMember,
            isOrganizationAdministrator,
            isSuperUser,
        } = gate;

        /**
         * Setup the page.
         */
        this.boot = async () => {
            if (!user.value) {
                // Load if user details are missing.
                await fetchCurrentUser();
            }

            if (!organization.value) {
                // Load the selected or primary organization.
                await fetchSelectedOrganization();
            }

            // Load the subscriptions.
            await subscriptions.execute();

            // Check if current subscription is active.
            // If the user is a super user, we can also redirect.
            if (isCurrentSubscriptionActive.value || isSuperUser.value) {
                return await this.redirect('/analysis');
            }
        };

        /**
         * Handle a logout request.
         */
        this.logout = async () => {
            await store.dispatch('logout');
            return await this.redirect('/login');
        };

        /**
         * Handle a redirect request.
         *
         * @param {Router.RouteLocationRaw} route
         * @returns {Promise<void | import('vue-router').NavigationFailure>}
         */
        this.redirect = async (route) => {
            return await router.push(route);
        };
    }
}

/**
 * Representation of the page context.
 */
class Page {
    /**
     * Exposes the public properties used by the page.
     * @param {Props} props
     * @param {State} state
     * @param {Actions} actions
     */
    static expose(props, state, actions) {
        return {
            store: props.store,
            router: props.router,
            gate: props.gate,
            state,
            actions,
        };
    }
}

/**
 * Helper class for creating messages.
 */
class Message {
    /**
     * Gets the title.
     * @returns {string}
     */
    static forTitle() {
        return 'Subscription Expired';
    }

    /**
     * Gets the description.
     * @returns {string}
     */
    static forDescription() {
        return 'This resource is only accessible with an active subscription.';
    }

    /**
     * Get the expired subscription message using the provided expiration date.
     * @param {Date} [dt]
     * @returns {string}
     */
    static forExpirationDate(dt) {
        const datestring =
            dt == null ? 'an unknown date' : dt.toLocaleDateString('en-ca');
        return `The most recent subscription expired on ${datestring}.`;
    }

    /**
     * Get the message for the user, based on its role status.
     * @param {boolean} isAdministrator
     * @returns {string}
     */
    static forUser(isAdministrator) {
        return isAdministrator === true
            ? Message.forAdministrator()
            : Message.forMember();
    }

    /**
     * Get the administrator specific message.
     * @returns {string}
     */
    static forAdministrator() {
        return "Please use the appropriate action to renew your organization's subscription.";
    }

    /**
     * Get the non-administrator specific message.
     * @returns {string}
     */
    static forMember() {
        return 'Organization subscriptions can be purchased by an organization administrator.';
    }
}

/**
 * Helper class for creating email objects.
 */
class Email {
    /**
     * Create a basic email link.
     * @param {Object} options
     * @param {string[]} [options.to]
     * @param {string[]} [options.cc]
     * @param {string[]} [options.bcc]
     * @param {string} [options.subject]
     * @param {string[]} [options.body]
     */
    static make(options) {
        return new Email(options).href;
    }

    /**
     * @param {Object} options
     * @param {string[]} [options.to]
     * @param {string[]} [options.cc]
     * @param {string[]} [options.bcc]
     * @param {string} [options.subject]
     * @param {string[]} [options.body]
     */
    constructor(options) {
        this.state = {
            to: options.to ?? [],
            cc: options.cc ?? [],
            bcc: options.bcc ?? [],
            subject: options.subject ?? '',
            body: options.body ?? [],
        };
    }

    /** Get the properly encoded string. */
    get href() {
        const { to, cc, bcc, subject, body } = this.state;

        // Get the emails.
        const _to = to?.length > 0 ? to.join(',') : '';

        // Get the CC emails.
        const _cc = cc?.length > 0 ? `cc=${cc.join(',')}` : null;

        // Get the BCC emails.
        const _bcc = bcc?.length > 0 ? `bcc=${bcc.join(',')}` : null;

        // Get the subject.
        const _subject = `subject=${encodeURIComponent(subject)}`;

        // Get the body.
        const _body = `body=${encodeURIComponent(body.join('\r\n'))}`;

        // Get the protocol.
        const protocol = `mailto:${_to}?`;

        // Get the query params.
        const query = [_cc, _bcc, _subject, _body]
            .filter((param) => !!param && param?.length > 0)
            .join('&');

        // Format and return the href link.
        const formatted = `mailto:${to}?${query}`;
        return formatted;
    }
}

/**
 * Helper class for creating interactions.
 */
export class Interaction {
    /**
     * Creates an interaction.
     * @param {Object} options
     * @param {string} options.id
     * @param {'link' | 'button'} options.type
     * @param {'primary' | 'secondary'} [options.theme]
     * @param {Vue.Ref<string> | Vue.ComputedRef<string>} [options.link]
     * @param {string} [options.label]
     * @param {string} [options.description]
     * @param {Vue.Ref<boolean> | Vue.ComputedRef<boolean>} [options.visible]
     */
    constructor(options) {
        this.id = options.id;
        this.theme = options.theme ?? 'primary';
        this.type = options.type ?? 'link';
        this.label = options.label ?? 'Button';
        this.link = options.link ?? ref('#');
        this.description =
            options.description ?? 'Short description of action.';
        this.visible = options.visible ?? ref(() => true);

        /** @type {Vue.EventHook<Interaction>} */
        const click = createEventHook();
        this.click = () => click.trigger(this);
        this.onClick = click.on;
    }
}

// <!-- COMPOSABLE -->
/**
 * Define composable that can optionally accept an already defined store.
 * @param {UseExpiredSubscriptionPageOptions} [options]
 * @returns {UseExpiredSubscriptionPageReturn}
 */
export const useExpiredSubscriptionPage = (options = {}) => {
    // Initialize the state and actions.
    const props = new Props(options);
    const state = new State(props);
    const actions = new Actions(props, state);

    // Expose the public properties.
    return Page.expose(props, state, actions);
};

// <!-- DEFAULT -->
export default useExpiredSubscriptionPage;
